$(document).on('turbolinks:load', function () {
    if($('.check-btn').length > 0){
        is_selected();
    };

    $('.check-btn').on('change', function () {
        is_selected();
    });

    function is_selected() {
        if($('.check-btn:checked').length > 0){
            $('.next-btn').attr('disabled', false);
        }else {
            $('.next-btn').attr('disabled', true);
        }
    };

    $('.file-input').on('change', function () {
        $('.resume-name').html($(this).val().split('\\').pop())
    });

    function validateForm() {
        var valid = true;
        $('.required').each(function() {
            valid &= !!$(this).val();
        });

        if(valid){
            $('.next-btn').attr('disabled', false);
        }else {
            $('.next-btn').attr('disabled', true);
        }
    }

    if($('.required').length > 0){
        validateForm();
    };

    $('.required').on('change keyup', function () {
        validateForm();
    });
});
